jQuery(document).ready(function($) {
	$('.hme-happenings-slider-wrapper').slick({
		autoplay: true,
		autoplaySpeed: 4000,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		arrows: false,
		dots: true,
		fade: true,
	});
}); /* end of as page load scripts */
